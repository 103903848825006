jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	// ----- carousel -----
	if ($('.carousel-indicators li.active').length == 0) {
		$('.carousel-indicators li:first').addClass('active');
	}
	if ($('.carousel-inner .item.active').length == 0) {
		$('.carousel-inner .item:first').addClass('active');
	}

	$('.dropdown .active').parents('.dropdown').addClass('active');

	$('.map').each(function () {

		var $this = $(this),
			loc = new google.maps.LatLng($this.data('lat'), $this.data('lng')),
			map = new google.maps.Map(this, {
				zoom: 15,
				scrollwheel: false,
				center: loc,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}),
			marker = new google.maps.Marker({
				position: loc,
				map: map,
			});
	});

	$('input[type="checkbox"].teruletek').on('change', function () {
		var $this = $(this),
			checked = $this.is(':checked'),
			$target = $($this.data('target'));
		if (checked) {
			$target.collapse('show').find('input[type="checkbox"]').removeAttr('disabled').removeProp('disabled');
		} else {
			$target.collapse('hide').find('input[type="checkbox"]').attr('disabled', 'disabled').prop('disabled', true);
		}
	});

	/*$('.kereses-lenyilo').on('click', function () {
	 if ($('#kereses-form').css('display') == 'none') {
	 $("#kereses-form").slideDown("fast");
	 }
	 else {
	 $("#kereses-form").slideUp("fast");
	 }
	 });*/

	$('.kereses-lenyilo').parent('div').on('click', function () {
		if ($('#kereses-form').css('display') == 'none') {
			$("#kereses-form").slideDown("fast");
		}
		else {
			$("#kereses-form").slideUp("fast");
		}
	});

	$('.search-close').on('click', function () {
		$("#kereses-form").slideUp("fast");
	});

	if ($('.tartalom-sav').height() > $('.oldalsav').height()) {
		$('.tartalom-sav').css('border-right', '1px solid #a3a3a3');
	}
	else {
		$('.oldalsav').css('border-left', '1px solid #a3a3a3');
	}

	$('.szoveg table').each(function () {
		$(this).wrap("<div class='table-responsive'></div>");
	});

});
function onSubmitAjanlat(token) {
	document.getElementById("ajanlatForm").submit();
}

function onSubmitHirlevel(token) {
	var form = $("#hirlevelForm");
	console.log(form);
	$(form).ajaxSubmit({
		method: 'POST',
		success: function (resp) {
			cbs.callbackHandler(form.data('callback'), resp, form);
			window.location='https://ergonom.hu/hirlevel/koszonjuk';
			//window.location='http://ergonom.fwl.hu/hirlevel/koszonjuk';

		}
	});
}

function onSubmitKepzes(token) {
	document.getElementById("kepzesForm").submit();
}

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('click', 'div.menu-doboz', function (e) {
	e.preventDefault();
	var link = $(this).children('.menu-link');
	var doboz = $(this);

	if (link.data('toggle') != 'dropdown') {
		window.location.href = link.attr('href');
	}
	else {
		if (doboz.hasClass('nyitott')) {
			doboz.removeClass('open');
			doboz.removeClass('nyitott');
		} else {
			doboz.addClass('open');
			doboz.addClass('nyitott');
		}
	}
});

$(document).on('click', 'div.menu-doboz > .menu-link', function () {

	var doboz = $(this).parent('.menu-doboz');
	if (doboz.hasClass('nyitott')) {
		doboz.removeClass('nyitott');
	} else {
		doboz.addClass('nyitott');
	}
});
$(document).on('click', 'div.menu-doboz  .almenu-link', function (e) {
	e.preventDefault();
	window.location.href = $(this).attr('href');
});

$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	morepages: function (resp, $elem) {
		$($elem.data('target')).append(resp);
		$elem.remove();
	},
	hirlevel: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			$elem.html(resp.uzenet);
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value : [value];
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
			});
		}
	}
};

var recaptcha = function () {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		grecaptcha.render($this[0], $this.data());
	})
};
